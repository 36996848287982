import React, { useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import axios from 'axios';
import './upComing.scss';
import { Link, useNavigate } from 'react-router-dom';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';

SwiperCore.use([Navigation, Pagination]);

const Schedule = () => {
    let navigate = useNavigate();

    const [schedule, setSchedule] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get('https://balkanflix-server.vercel.app/api/schedule/animeSchedule');
                const convertedData = data.map(item => ({
                    ...item,
                    originalTime: item.time,
                    time: convertToBelgradeTime(item.time),
                    date: formatDate(item.time),
                    countdown: calculateCountdown(item.time),
                    imageSrc: getImageUrl(item.img),
                }));
                setSchedule(convertedData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setSchedule(prevSchedule => prevSchedule.map(item => ({
                ...item,
                countdown: calculateCountdown(item.originalTime),
            })));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const convertToBelgradeTime = (isoString) => {
        const date = new Date(isoString);
        const options = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'Europe/Belgrade' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'Europe/Belgrade' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    const calculateCountdown = (isoString) => {
        const eventDate = new Date(isoString);
        const now = new Date();
        const difference = eventDate - now;

        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);
            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
            return 'USKORO';
        }
    };

    const getImageUrl = (imageName) => {
        return `https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${imageName}`;
    };

    return (
        <div className="schedule-container">
            <div className="schedule-header">
                <h1>Procenjeno vreme izlaska</h1>
            </div>
            {loading ? (
                <div className="loading-spinner"></div>
            ) : (
                <>
                    {/* Navigation arrow for previous slide */}
                    <button className="swiper-button-prev" />

                    {/* Swiper container */}
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        navigation={{
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }}
                        modules={[Autoplay]}
                        autoplay={{delay: 4000}}
                        grabCursor={true}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            }
                        }}
                    >
                        {schedule.map((item, index) => (
                            <SwiperSlide key={index}>
                                <Link to={`/${item.title_params}`} className="schedule-item">
                                    <div className="anime-image">
                                        <img src={item.imageSrc} alt={item.title} />
                                    </div>
                                    <div className="details">
                                        <div className="title">{item.title}</div>
                                        <div className="episode">Epizoda {item.ep}</div>
                                        <div className="date-time">
                                            <div className="date">{item.date}</div>
                                            <div className="time">{item.time}</div>
                                        </div>
                                        <div className="countdown">{item.countdown}</div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <button className="swiper-button-next" />
                </>
            )}
        </div>
    );
};

export default Schedule;