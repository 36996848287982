// src/pages/MainHome.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { OutlineButton } from '../components/button/Button';
import HeroSlide from '../components/hero-slide/HeroSlide';
import MovieList from '../components/movie-list/MovieList';
import useDocumentTitle from '../../Title/useDocumentTitle';
import { category } from '../api/tmdbApi';
import Schedule from "../../Buttons/upComing/UpComing";
import TopUsersModal from "../components/top-users/top-users"
import axios from 'axios';

const MainHome = () => {
    useDocumentTitle('Balkanflix Anime - Pocetna - Gledaj anime online sa prevodom');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [topUsers, setTopUsers] = useState([]);
    const [userPosition, setUserPosition] = useState(null);
    const username = localStorage.getItem("username");

    useEffect(() => {
        const fetchTopUsers = async () => {
            try {
                const response = await axios.get("https://balkanflix-server.vercel.app/api/auth/getTopUsersByEpisodesWatchedFull");
                const users = response.data.topUsers;
                setTopUsers(users.slice(0, 5));

                // Assuming we have a way to get the current user's ID, e.g., from context or auth state
                const currentUserId = 'currentUser'; // Replace with actual current user ID
                const currentUserIndex = users.findIndex(user => user._id === currentUserId);
                if (currentUserIndex >= 0) {
                    setUserPosition(currentUserIndex + 1);
                }
            } catch (error) {
                console.error('Error fetching top users:', error);
            }
        };

        fetchTopUsers();
    }, []);

    const toggleModal = () => {
        setIsModalOpen(prev => !prev);
    };

    return (
        <>
            <HeroSlide />
            <div className="home-container">
                <div className="home-anime-list">
                    <div className="section mb-3">
                        <div className="section__header mb-2">
                            <h2>Najnovije</h2>
                            <Link to="/category/newest">
                                <OutlineButton className="small">Više</OutlineButton>
                            </Link>
                        </div>
                        <MovieList category={category.newest} type="newest" />
                    </div>
                </div>
                <div className="anime-upcoming">
                    <Schedule />
                </div>
            </div>

            {/* Arrow button and modal */}
            <TopUsersModal
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                topUsers={topUsers}
                username={username}
                userPosition={userPosition}
            />
        </>
    );
}

export default MainHome;
