import "./navbardb.scss"
import {useEffect, useState} from "react";
import axios from "axios";



const Navbardb  = () => {
    const [username, setUsername]  = useState("");
    const [pfp, setPfp]  = useState("");

    useEffect(() => {

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        };

        const fetchUser = async () => {
            try {
                const {data} = await axios.get('https://balkanflix-server.vercel.app/api/private', config);
                setUsername(data.username);
                setPfp(data.pfp);

            } catch (error) {
                console.error("Server error", error);
            }
        }

        fetchUser();

    }, []);

    return (
        <div className="navbar">
            <div className="logodb">
                <img src="/Balkanflix-removebg.png" alt="logo" width="50px" height="55px" viewBox="-13.5 0 80 80"/>
                <span>Admin Dashboard</span>
            </div>
            <div className="iconsdb">
                {/*<img src="/Dashboard/search.svg" className="icondb" alt=''/>*/}
                {/*<img src="/Dashboard/app.svg" className="icondb" alt=''/>*/}
                {/*<img src="/Dashboard/expand.svg" className="icondb" alt=''/>*/}
                {/*<div className="notification">*/}
                {/*    <img src="/Dashboard/notifications.svg" className="" alt="dash"/>*/}
                {/*    <span>7</span>*/}
                {/*</div>*/}
                <div className="user">
                    <span>{username}</span>
                    <img src={pfp}  alt="Profilna"/>
                </div>
                {/*<img src="/Dashboard/settings.svg" className="" alt="dash"/>*/}
            </div>
        </div>
    )
}

export default Navbardb;
